import { FC, useContext, useState } from "react";
import { InputWithValidation } from "@/libs/input/inputWithValidation";
import styles from "./Registration.module.scss";
import clsx from "clsx";
import { Button } from "@/libs/button/button";
import { useForm } from "react-hook-form";
import {
  IAccessToken,
  IBackendError,
  IBackendFieldError,
} from "@/helpers/interfaces";
import { ILoginBody } from "@/api/services/EmployeeService";
import { useLogin } from "@/hooks/useEmployee";
import { ACCESS_TOKEN_NAME } from "@/const/index";
import Cookies from "js-cookie";
import { AxiosError } from "axios";
import { ModalContext } from "@/contexts/ModalContext";

interface IRegistration {
  onSuccess: () => void;
}

const INITIAL_STATUS_STATE = {
  isSuccess: false,
  isLoading: false,
  isError: false,
};

const INITIAL_FORM_STATE: ILoginBody = {
  name: "",
  surname: "",
};

const Registration: FC<IRegistration> = (props) => {
  const { onSuccess } = props;
  const [form, setForm] = useState<ILoginBody>(INITIAL_FORM_STATE);
  const [status, setStatus] = useState(INITIAL_STATUS_STATE);
  const [errorsApi, setErrorsApi] = useState<IBackendFieldError<ILoginBody>>(
    {},
  );
  // eslint-disable-next-line
  const [_, setModalValue] = useContext(ModalContext);

  const { mutate: sendForm } = useLogin();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ILoginBody>({
    mode: "onSubmit",
    defaultValues: INITIAL_FORM_STATE,
  });

  const onSubmit = () => {
    setStatus((prev) => ({ ...prev, isLoading: true }));
    sendForm(form, {
      onSuccess: (data: IAccessToken) => {
        setStatus({ isLoading: false, isSuccess: true, isError: false });

        if ("access" in data && data.access) {
          Cookies.set(ACCESS_TOKEN_NAME, data.access.token, {
            expires: 365,
            secure: true,
            sameSite: "strict",
          });
        }
        onSuccess();
      },
      onError: (error: AxiosError<IBackendError<ILoginBody>>) => {
        if (error.status === 404) {
          setModalValue((prev) => ({
            ...prev,
            check: true,
          }));
        }
        const errorData = error?.response?.data;
        if (errorData && errorData.errors) setErrorsApi(errorData.errors);

        setStatus({ isLoading: false, isSuccess: false, isError: true });
      },
    });
  };

  return (
    <section className={styles.registration}>
      <div className={clsx(styles.container, "max-container")}>
        <div className={styles.block}>
          <p className={styles.title}>
            Для того, чтобы проголосовать укажите, пожалуйста, свои фамилию и
            имя:
          </p>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.fields}>
              <InputWithValidation
                control={control}
                name="surname"
                placeholder="Фамилия"
                value={form.surname}
                disabled={status.isLoading}
                error={
                  !!errorsApi.surname
                    ? errorsApi.surname[0]
                    : errors?.surname?.message
                }
                onChange={(value) => setForm({ ...form, surname: value })}
                rules={{
                  required: "Вы не заполнили обязательное поле",
                }}
              />
              <InputWithValidation
                control={control}
                name="name"
                placeholder="Имя"
                value={form.name}
                disabled={status.isLoading}
                error={
                  !!errorsApi.name ? errorsApi.name[0] : errors?.name?.message
                }
                onChange={(value) => setForm({ ...form, name: value })}
                rules={{
                  required: "Вы не заполнили обязательное поле",
                }}
              />
            </div>
            <Button
              type="submit"
              disabled={status.isLoading}
              className={styles.btn}
              isBlock
            >
              Отправить
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Registration;
