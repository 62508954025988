import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  IAccessToken,
  IBackendError,
  IBackendResponse,
} from "@/helpers/interfaces";
import { toast } from "react-toastify";
import EmployeeService, {
  ILoginBody,
  IVoteBody,
} from "@/api/services/EmployeeService";

export const useLogin = () => {
  return useMutation({
    mutationKey: ["login"],
    mutationFn: (data: ILoginBody) => {
      return EmployeeService.login(data);
    },
    onSuccess: (data: IAccessToken) => {
      return data;
    },
    onError: (error: AxiosError<IBackendError<ILoginBody>>) => {
      toast.error(error.response?.data.message, {
        position: "bottom-right",
      });
      return error;
    },
  });
};

export const useVote = () => {
  return useMutation({
    mutationKey: ["vote"],
    mutationFn: (data: IVoteBody) => {
      return EmployeeService.vote(data);
    },
    onSuccess: (data: IBackendResponse) => {
      return data;
    },
    onError: (error: AxiosError<IBackendError<IVoteBody>>) => {
      toast.error(error.response?.data.message, {
        position: "bottom-right",
      });
      return error;
    },
  });
};
