import { FC, Fragment, useContext, useEffect } from "react";
import styles from "./Account.module.scss";
import { Card } from "@/libs/Card/Card";
import clsx from "clsx";
import { ModalContext } from "@/contexts/ModalContext";
import { useGetTeams } from "@/hooks/useTeams";
import Cookies from "js-cookie";
import { ACCESS_TOKEN_NAME } from "@/const/index";
import { useVote } from "@/hooks/useEmployee";
import { IBackendError } from "@/helpers/interfaces";
import { AxiosError } from "axios";
import { IVoteBody } from "@/api/services/EmployeeService";

interface IAccount {}
const Account: FC<IAccount> = () => {
  // eslint-disable-next-line
  const [_, setModalValue] = useContext(ModalContext);
  const { data, isLoading, isError } = useGetTeams();
  const { mutate } = useVote();

  const handleClick = (uuid: string) => {
    mutate(
      { team: uuid },
      {
        onSuccess: () => {
          setModalValue((prev) => ({
            ...prev,
            success: true,
            payload: {
              callback: logout,
            },
          }));
        },
        onError: (error: AxiosError<IBackendError<IVoteBody>>) => {
          if (error.status === 400) {
            setModalValue((prev) => ({
              ...prev,
              voice: true,
            }));
          }
        },
      },
    );
  };

  useEffect(() => {
    if (isError) {
      logout();
    }
  }, [isError]);

  const logout = () => {
    Cookies.remove(ACCESS_TOKEN_NAME);
    window.location.reload();
  };

  return (
    <section className={styles.account}>
      <div className={clsx(styles.container, "max-container")}>
        {!isLoading && data && data.data.length ? (
          <div className={styles.cards}>
            {data?.data.map((item) => (
              <Fragment key={item.uuid}>
                <Card
                  title={item.name}
                  onClick={() => handleClick(item.uuid)}
                />
              </Fragment>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};

export default Account;
