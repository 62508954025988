import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  ModalContext,
  IModalContextValue,
  INITIAL_MODAL_STATE,
} from "@/contexts/ModalContext";
import { AnimatePresence, motion } from "framer-motion";
import { SuccessModal } from "@/components/common/modals/Success/Success";
import { CheckModal } from "@/components/common/modals/Check/Check";
import { VoiceModal } from "@/components/common/modals/Voice/Voice";

interface IPageWrapper {
  children: React.ReactNode;
}

const PageWrapper: React.FC<IPageWrapper> = (props) => {
  const { children } = props;
  const [modalValue, setModalValue] =
    useState<IModalContextValue>(INITIAL_MODAL_STATE);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000,
            refetchOnMount: true,
            retry: 0,
          },
        },
      }),
  );

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ModalContext.Provider value={[modalValue, setModalValue]}>
          <div className="">{children}</div>

          <AnimatePresence>
            {modalValue.success && (
              <motion.div
                style={{
                  width: "100%",
                  height: "100%",
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <SuccessModal />
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {modalValue.check && (
              <motion.div
                style={{
                  width: "100%",
                  height: "100%",
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <CheckModal />
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {modalValue.voice && (
              <motion.div
                style={{
                  width: "100%",
                  height: "100%",
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <VoiceModal />
              </motion.div>
            )}
          </AnimatePresence>
          <ToastContainer />
        </ModalContext.Provider>
      </QueryClientProvider>
    </>
  );
};

export default PageWrapper;
