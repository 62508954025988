import { FC, HTMLAttributes, JSX } from 'react'
import styles from './card.module.scss'
import { Button } from '../button/button'
import clsx from 'clsx'

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  onClick?: () => void
}

export const Card: FC<CardProps> = (props): JSX.Element => {
  const {
    className,
    title,
    onClick,
    ...rest
  } = props

  return (
    <div className={clsx(styles.card, className)} {...rest}>
      <p className={styles.title}>{ title }</p>
      <Button className={styles.button} type="button" onClick={onClick}>Проголосовать</Button>
    </div>
  )
}
