import { useQuery } from "@tanstack/react-query";
import TeamsService from "@/api/services/TeamsService";

export const useGetTeams = () => {
  return useQuery({
    queryKey: ["teams"],
    queryFn: () => {
      return TeamsService.teams();
    },
  });
};
