import { ModalContext } from "@/contexts/ModalContext";
import { Modal } from "@/libs/modal/Modal";
import { FC, JSX, useContext } from "react";

export const SuccessModal: FC = (): JSX.Element => {
  const [modalValue, setModalValue] = useContext(ModalContext);
  const payload = modalValue.payload;

  const handleClose = () => {
    setModalValue((prev) => ({
      ...prev,
      success: false,
    }));
    if (payload && payload.callback) {
      payload.callback();
    }
  };

  return (
    <Modal title="Спасибо!<br /> Ваш голос принят" onClose={handleClose} />
  );
};
