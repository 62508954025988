import { FC } from "react";
import { Control, Controller } from "react-hook-form";

import { InputProps, Input } from "@/libs/input/Input";

type Props = {
  control: Control<any>;
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  error?: any;
  rules?: {
    [key: string]: any;
  };
};

export type IInputWithValidation = Props & InputProps;

export const InputWithValidation: FC<IInputWithValidation> = ({
  name,
  control,
  rules,
  error,
  value,
  onChange,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Input
          name={name}
          error={error}
          onChange={(value) => {
            field.onChange(value);
            if (onChange) onChange(value);
          }}
          value={field.value}
          {...props}
        />
      )}
      {...props}
    />
  );
};
