import { ModalContext } from '@/contexts/ModalContext'
import { Modal } from '@/libs/modal/Modal'
import { FC, JSX, useContext } from 'react'

export const CheckModal: FC = (): JSX.Element => {
  // eslint-disable-next-line
  const [_, setModalValue] = useContext(ModalContext)

  const handleClose = () => {
    setModalValue(prev => ({
      ...prev,
      check: false
    }))
  }

  return (
    <Modal
      title="Сотрудника с такими <br />
      данными не нашли. Перепроверьте <br />
      введенные данные."
      onClose={handleClose}
    />
  )
}


