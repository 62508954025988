import { axiosRequest } from "@/api/axios-request";

export interface ITeamsData {
  data: ITeam[];
}

export interface ITeam {
  uuid: string;
  name: string;
}

export default class TeamsService {
  private static baseUrl: string = "/teams";

  public static teams() {
    const method = "GET";
    const url = `${this.baseUrl}`;

    return axiosRequest<undefined, ITeamsData>({
      method,
      url,
    });
  }
}
