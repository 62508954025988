import React from "react";
import styles from "./Header.module.scss";
import clsx from "clsx";

interface IHeader {}

const Header: React.FC<IHeader> = () => {
  return (
    <header className={styles.header}>
      <div className={clsx(styles.container, "max-container")}>
        <img
          className={styles.logo}
          src={require("@/assets/img/logo.png")}
          alt="Knauf"
          // width={10}
          // height={10}
        />

        <p className={styles.title}>
          Конференция менеджеров <br /> внешнего сбыта
        </p>
      </div>
    </header>
  );
};

export default Header;
