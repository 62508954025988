import { FC, JSX, useEffect } from 'react'
import styles from './modal.module.scss'
import clsx from 'clsx';

interface ModalProps {
  isOpen?: boolean;
  maxWidth?: number;
  title?: TrustedHTML | string;
  onClose?: () => void
}

export const Modal: FC<ModalProps> = (props): JSX.Element => {
  const {
    isOpen = false,
    maxWidth,
    title,
    onClose
  } = props

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isOpen]);

  return (
    <div className={clsx(styles.modal)}>
      <div 
        className={styles.overflow}
        onClick={() => onClose && onClose()}
      />

      <div  
        className={styles.container}
        style={{
          maxWidth: maxWidth + 'px'
        }}
      >
        <button 
          className={styles.close}
          onClick={() => onClose && onClose()}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.41421 1.65092C2.19526 0.86987 3.46159 0.86987 4.24264 1.65092L22.0059 19.4142C22.7869 20.1952 22.7869 21.4615 22.0059 22.2426C21.2248 23.0236 19.9585 23.0236 19.1775 22.2426L1.41421 4.47935C0.633165 3.6983 0.633165 2.43197 1.41421 1.65092Z" fill="#FF0000"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.88852 22.0059C1.10747 21.2248 1.10747 19.9585 1.88852 19.1775L19.6518 1.41421C20.4328 0.633163 21.6991 0.633164 22.4802 1.41421C23.2612 2.19526 23.2612 3.46159 22.4802 4.24264L4.71695 22.0059C3.9359 22.7869 2.66957 22.7869 1.88852 22.0059Z" fill="#FF0000"/>
          </svg>
        </button>

        <div className={styles.inner}>
          {title ? (
            <h3
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}