import React, { FC, JSX, InputHTMLAttributes, useEffect, useState } from 'react'
import styles from './Input.module.scss'
import type { FieldError } from 'react-hook-form'
import clsx from 'clsx'

type IInputType = "text" | "password" | "number" | "tel";

// type IInputMode =
//   | "none"
//   | "text"
//   | "numeric"
//   | "decimal"
//   | "tel"
//   | "url"
//   | "email"
//   | "search"

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  name: string;
  label?: string;
  placeholder?: string;
  value?: string | number;
  type?: IInputType;
  error?: string  | FieldError;
  onChange?: (val: string) => void
}

export const Input: FC<InputProps> = ( props ): JSX.Element => {
  const {
    name,
    label,
    placeholder,
    value,
    className,
    type = "text",
    error,
    onChange,
    ...rest
  } = props

  const [content, setContent] = useState<string | number | undefined>(value)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    setContent(newValue)

    if (onChange) onChange(newValue)
  }

  useEffect(() => setContent(value), [value]);

  return (
    <div>
      <input
        className={clsx(className, styles.input, {
          [styles.error]: error
        })}
        placeholder={placeholder}
        name={name}
        value={content}
        type={type}
        onChange={handleChange}
        {...rest}
      />
      {error ? (
        <p className={styles["error-text"]}>{error as string}</p>
      ) : (
        <></>
      )}
    </div>
  )
}
