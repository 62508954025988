import {
  createContext,
  Dispatch,
  SetStateAction
} from 'react'

export const INITIAL_MODAL_STATE: IModalContextValue = {
  payload: null,
  success: false,
  check: false,
  voice: false
}

export interface IModalContextValue {
  payload: any;
  success: boolean;
  check: boolean;
  voice: boolean;
}

type TModalContext = [
  modalValue: IModalContextValue,
  setModalValue: Dispatch<SetStateAction<IModalContextValue>>,
];

export const ModalContext = createContext<TModalContext>([
  {} as IModalContextValue,
  () => ({}),
]);