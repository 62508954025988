import React, { FC, ButtonHTMLAttributes, JSX } from 'react'
import clsx from 'clsx'
import styles from './button.module.scss'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isBlock?: boolean;
  size?: 'small' | 'default'
}

export const Button: FC<ButtonProps> = (props): JSX.Element => {
  const {
    className,
    children,
    isBlock,
    size = 'default',
    ...rest
  } = props


  return (
    <button
      className={clsx(styles.button, className, {
        [styles.block]: isBlock
      }, [styles[size]])}
      {...rest}
    >
      {children}
    </button>
  )
}