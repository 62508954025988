import { axiosRequest } from "@/api/axios-request";
import { IAccessToken, IBackendResponse } from "@/helpers/interfaces";

export interface ILoginBody {
  surname: string;
  name: string;
}

export interface IVoteBody {
  team: string;
}

export default class EmployeeService {
  private static baseUrl: string = "/employee";

  public static login(data: ILoginBody) {
    const method = "POST";
    const url = `${this.baseUrl}/login`;

    return axiosRequest<ILoginBody, IAccessToken>({
      method,
      url,
      data,
    });
  }
  public static vote(data: IVoteBody) {
    const method = "POST";
    const url = `${this.baseUrl}/vote`;

    return axiosRequest<IVoteBody, IBackendResponse>({
      method,
      url,
      data,
    });
  }
}
