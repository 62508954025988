import axios, { AxiosRequestConfig, AxiosRequestHeaders, Method } from "axios";
import Cookies from "js-cookie";
import { ACCESS_TOKEN_NAME, QUEST_TOKEN_NAME } from "@/const/index";

export type ApiParams<T> = {
  data?: T;
  method: Method;
  url: string;
  token?: string;
  contentType?: string;
  baseURL?: string;
  params?: object;
  timeout?: number;
};

const Url = process.env["REACT_APP_BACK_URL"] || "";

export const axiosRequest = async <TRequestData, TResponseData = void>({
  data,
  method,
  url,
  baseURL,
  token: SSRToken,
  params,
  contentType,
  header,
  timeout = 40000,
}: ApiParams<TRequestData> & {
  header?: AxiosRequestHeaders;
}): Promise<TResponseData> => {
  const config: AxiosRequestConfig = {
    baseURL,
    data,
    method,
    url,
    params,
    withCredentials: false,
    timeout,
    headers: {},
  };

  const token =
    Cookies.get(ACCESS_TOKEN_NAME) || Cookies.get(QUEST_TOKEN_NAME) || SSRToken;

  config.url = url;
  if (!config.baseURL) config.baseURL = baseURL || Url;

  if (header) {
    config.headers = { ...config.headers, ...header };
  }

  if (token)
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };

  if (contentType) {
    config.headers = {
      ...config.headers,
      "content-type": contentType,
    };
  }

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
