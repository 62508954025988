import React, { useEffect, useState } from "react";
import { PAGES } from "@/helpers/enums";
import Registration from "@/components/pages/registration/Registration";
import Account from "@/components/pages/account/Account";
import Header from "@/components/common/Header/Header";
import PageWrapper from "@/components/common/PageWrapper/PageWrapper";
import Cookies from "js-cookie";
import { ACCESS_TOKEN_NAME } from "@/const/index";

function App() {
  const [activePage, setActivePage] = useState<PAGES>(PAGES.REGISTRATION);

  useEffect(() => {
    if (Cookies.get(ACCESS_TOKEN_NAME)) {
      setActivePage(PAGES.ACCOUNT);
    }
  }, []);

  return (
    <PageWrapper>
      <Header />
      <main>
        {activePage === PAGES.REGISTRATION ? (
          <Registration onSuccess={() => setActivePage(PAGES.ACCOUNT)} />
        ) : (
          <></>
        )}
        {activePage === PAGES.ACCOUNT ? <Account /> : <></>}
      </main>
    </PageWrapper>
  );
}

export default App;
